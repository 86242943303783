import {actions} from '../state/ParamsState'
import {APP_MODES} from "./Enums";

export const setupConfig = (store, renderApp) => {
    return new Promise((resolve, reject)=> {
        window.CITIZEN_PAY_COMPONENT={}
        window.CITIZEN_PAY_COMPONENT.init = (params) => {
            if(params) {
                const {mode = APP_MODES.STANDALONE} = params
                actions.setParam(params)(store.dispatch, store.getState)
                renderApp(mode)
            }
        }
        if(window.CITIZEN_PAY_COMPONENT){
            resolve()
        }
    })
}
