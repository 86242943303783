import React from 'react';

const WhiteStep2 = () => {
    return (
        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
            <title>Group 4</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-196.000000, -221.000000)">
                    <g id="Group-6-Copy" transform="translate(9.000000, 0.000000)">
                        <g id="Group-4" transform="translate(187.000000, 221.000000)">
                            <circle id="Oval-Copy" fill="#484848" cx="16" cy="16" r="16"/>
                            <path d="M19.8125,22 L19.8125,20.4296875 L14.609375,20.4296875 L14.609375,20.296875 L17.09375,17.8515625 C19.0703125,15.8984375 19.640625,14.9765625 19.640625,13.671875 C19.640625,11.8046875 18.1171875,10.453125 16,10.453125 C13.7265625,10.453125 12.125,11.921875 12.125,14.0234375 L13.78125,14.0234375 C13.78125,12.796875 14.6171875,11.96875 15.890625,11.96875 C17.140625,11.96875 17.8828125,12.78125 17.8828125,13.8515625 C17.8828125,14.7421875 17.5078125,15.2890625 16.078125,16.7578125 L12.21875,20.734375 L12.21875,22 L19.8125,22 Z" id="2" fill="#FFFFFF" fillRule="nonzero"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default WhiteStep2;
