import {createAction} from '../utils/reduxUtils'
import {THEME_MODES, THEME_STYLES} from "../utils/Enums";

const types = {}
types.SET_PARAMS = 'SET_PARAMS'
types.SET_STYLES = 'SET_STYLES'
types.CHANGE_THEME = 'CHANGE_THEME'

const setParamsAction = createAction(types.SET_PARAMS)
const setStylesAction = createAction(types.SET_STYLES)
const changeThemeAction = createAction(types.CHANGE_THEME)

const setParam = (newParams) => (dispatch, getState) => {
    const {params} = getState().ParamsReducer
    const theme = newParams?.theme ? newParams.theme : params?.theme
    let payload =  {
        ...newParams,
        styles: {
            ...params?.styles,
            ...THEME_STYLES[theme],
            ...newParams.styles
        },
        theme: theme
    }
    dispatch(setParamsAction(payload))
}

const setStyles = (newStyles) => (dispatch, getState) => {
    const {params} = getState().ParamsReducer

    let payload =  {...params?.styles, ...THEME_STYLES[params.theme], ...newStyles}

    dispatch(setStylesAction(payload))
}


const changeTheme = (theme) => (dispatch, getState) =>{
    const {params} = getState().ParamsReducer

    let payload =  {
        styles: {
            ...params?.styles,
            ...THEME_STYLES[theme],
        },
        theme: theme
    }
    dispatch(changeThemeAction(payload))
}

export const actions = {
    setParam,
    setStyles,
    changeTheme
}

const initialState = {
    params: {
        paymentDetails: {
            apiKey: '',
            publicApiKey: '',
            customerEmailAddress: '',
            merchantEmailAddress: '',
            amount: '',
            currency: '',
            paymentGiro: '',
            shortReference: '',
            detailedReference: '',
            customerDeviceOs: '',
            customerIpAddress: '',
        },
        theme: THEME_MODES.LIGHT,
        styles: {
            ...THEME_STYLES[THEME_MODES.LIGHT]
        }
    }
}

//Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_PARAMS:
            return ({...state, params:{ ...state.params,...action.payload}})
        case types.SET_STYLES:
            return ({...state, params:{ ...state.params, styles:{...action.payload}}})
        case types.CHANGE_THEME:
            return ({
                ...state,
                params: {
                    ...state.params,
                    styles: {...action?.payload?.styles},
                    theme: action.payload.theme
                }
            })
        default:
            return state
    }
}
