import {createUseStyles} from "react-jss";
import inputStyles from "./components/inputStyles";
import selectStyles from "./components/selectStyles";
import radioButtonStyles from "./components/radioButtonStyles";
import baseStyles from "./baseStyles";
import StylePickerStyles from "./components/StylePickerStyles";
import PreviewStyles from "./views/PreviewStyles";
import buttonStyles from "./components/buttonStyles";
import checkoutComponentStyles from "./components/checkoutComponentStyles";

export default createUseStyles({
    ...inputStyles,
    ...selectStyles,
    ...radioButtonStyles,
    ...baseStyles,
    ...StylePickerStyles,
    ...PreviewStyles,
    ...buttonStyles,
    ...checkoutComponentStyles
})
