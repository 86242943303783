import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import ColorPicker from "../../../Components/ColorPicker";
import CheckBox from "../../../Components/CustomInputComponents/Checkbox";
import fonts, {injectFonts} from "../../../styles/fonts";
import Select from "../../../Components/CustomInputComponents/Select";
import TextField from "../../../Components/CustomInputComponents/TextField";
import GetFonts from "../../../networking/calls/FetchFonts";
import {debounce} from "../../../utils/commonUtils";
import useStyles from "../../../styles"

const fetchFonts = debounce((value, onChange, scope) => {
    GetFonts(value).then(fonts => {
        injectFonts(fonts.data).then(() => {
            onChange(value, scope, 'fontFamily')
        })
    }).catch(() => {
        console.error('Error fetching font')
    })
}, 1000)

const StylePicker = ({values = {}, onChange, scope, title, canHide}) => {
    const [isCustomFont, setIsCustomFont] = useState(false)
    const [customFont, setCustomFont] = useState('')

    const classes = useStyles()

    useEffect(() => {
        if (values.fontFamily !== fonts.$citizenFontRegularSF) {
            setIsCustomFont(true)
        } else {
            setIsCustomFont(false)
        }

    }, [values.fontFamily])

    const handleFontChange = (value) => {

        if (value === fonts.customFont) {
            setIsCustomFont(true)
        } else {
            setIsCustomFont(false)
            setCustomFont('')
            onChange(value, scope, 'fontFamily')
        }
    }

    const handleCustomFontChange = (value) => {
        setCustomFont(value)
        fetchFonts(value, onChange, scope)
    }

    const options = Object.values(fonts).filter(font => typeof font === 'string').map(font => ({
        value: font,
        name: font
    }))
    return (
        <div className={classes.stylePickerContainer}>

            <div className={classes.stylePickerTitle}>{title}</div>

            <div className={classes.stylePickerRow}>
                <span className={classes.stylePickerLabel}>BackgroundColor: </span>
                <ColorPicker value={values.backgroundColor} onChange={onChange} scope={scope} name={'backgroundColor'}/>
            </div>

            <div className={classes.divider}/>

            <div className={classes.stylePickerRow}>
                <span className={classes.stylePickerLabel}>Color: </span>
                <ColorPicker value={values.color} onChange={onChange} scope={scope} name={'color'}/>
            </div>

            <div className={classes.divider}/>

            <div className={classes.stylePickerRow}>
                <span className={classes.stylePickerLabel}>Font: </span>
                <div className={classes.stylePickerInput}>
                    <Select value={isCustomFont ? fonts.customFont : values.fontFamily}
                            hasEmptyOption={false}
                            name={'fontFamily' + scope}
                            onChange={({target: {value}}) => handleFontChange(value)}
                            options={options}/>
                </div>
            </div>

            {isCustomFont && <div className={classes.stylePickerRow}>
                <span className={classes.stylePickerNote}>The font that is inserted into custom font text field is fetched from fonts.googleapis.com.
                    If you use custom font in your site, just add the name here and you will see the font changes live.</span>
                <div className={classes.stylePickerInput}>
                    <TextField placeholder={'Insert font-family'}
                               onChange={({target: {value}}) => handleCustomFontChange(value)} value={customFont}/>
                </div>
            </div>}


            {canHide && <Fragment>
                <div className={classes.divider}/>
                <div className={classes.stylePickerRow}>
                    <span className={classes.stylePickerLabel}>Hide section: </span>
                    <CheckBox value={values.display === 'none'}
                              onChange={({target: {value}}) => onChange(value ? 'none' : undefined, scope, 'display')}/>
                </div>
            </Fragment>}

        </div>
    );
};

StylePicker.propTypes = {
    values: PropTypes.shape({
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
        fontFamily: PropTypes.string,
        display: PropTypes.string
    }),
    onChange: PropTypes.func,
    scope: PropTypes.string,
    title: PropTypes.string,
    canHide: PropTypes.bool
};

export default StylePicker;
