import React from 'react'
import PropTypes from 'prop-types'
import BaseSelect from './BaseSelect'
import useStyles from '../../styles'

const SelectItem = ({name, value, isSelected, onClick}) =>{
  const classes = useStyles()

  return (
      <option className={`${classes.selectItem} ${isSelected ? classes.selected : ''}`} onClick={onClick} value={value}>{name}</option>
  )
}

const Select = ({options, multiple, value, placeholder, onChange, name, disabled, hasEmptyOption}) => {

  let SelectOptions = options.map(option => {
    let isSelected = option.value === value
    if (multiple) {
      isSelected = value && value.indexOf(option.value) > -1
    }
    return <SelectItem elementName={'SelectItem'} key={option.name} value={option.value} name={option.name} isSelected={isSelected}/>
  })

  if (hasEmptyOption) {
    SelectOptions = [<SelectItem elementName={'SelectItem'} key={'selectEmptyOption' + name} value={null} name={'All'}/>, ...SelectOptions]
  }

  return (
    <BaseSelect onChange={onChange}
                disabled={disabled}
                options={options}
                name={name}
                value={value}
                placeholder={placeholder}
                multiple={multiple}>
      {SelectOptions}
    </BaseSelect>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string
    })).isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hasEmptyOption: PropTypes.bool
}

Select.defaultProps = {
  options: [],
  multiple: false,
  hasEmptyOption: true
}

export default Select
