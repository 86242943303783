import {post} from "../api";

const PaymentInit = (apiKey, data) => {
    const url = `/payments/payment-session`
    const headers = {
        'Content-type': 'application/json',
        'AuthorizationCitizen': apiKey
    }

   return post({url, data, headers})
}

export default PaymentInit
