import defaultColors from './colors'

const baseStyles = {
    divider: {
        height:'1px',
        backgroundColor: defaultColors.$citizenLightGrey,
        width: '100%',
        margin:'8px 0'
    }
}

export default baseStyles
