import React from 'react';

const Step1 = () => {
    return (
        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
            <title>Group 2</title>
            <desc>Created with Sketch.</desc>
            <g id="dropdown-variations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="TEST" transform="translate(-58.000000, -194.000000)">
                    <g id="Group-10">
                        <g id="Group-11" transform="translate(16.000000, 194.000000)">
                            <g id="Group-2" transform="translate(42.000000, 0.000000)">
                                <circle id="Oval" fill="#F0F2F5" cx="16" cy="16" r="16"/>
                                <polygon id="1" fill="#767B83" fillRule="nonzero"
                                         points="17.671875 22 17.671875 10.7265625 15.9296875 10.7265625 12.9609375 12.8203125 12.9609375 14.609375 15.7890625 12.609375 15.921875 12.609375 15.921875 22"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Step1;
