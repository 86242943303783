import defaultColors from "../colors";
import {createFonts} from "../fonts";

const selectStyles = {
    select: {
        border: '1px solid #dbdbdb',
        borderRadius: 2,
        backgroundPosition: 'calc(100% - 12px)',
        backgroundSize: '12px 7.5px',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        padding: '8px 24px 8px 16px',
        margin: 0,
        '&:focus': {
            borderColor: defaultColors.$citizenPurple
        },

        '&$search': {
            borderRadius: 21
        }
    },
    search: {}
}

const selectItemStyles = {
    selectItem: {
        ...createFonts(14),
        textAlign: 'initial',
        color: '#767b83',
        padding: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F9F9F9'
        },

        '& $selected': {
            color: defaultColors.$citizenWhite,
            backgroundColor: defaultColors.$citizenPurple,
            '&:hover': {
                backgroundColor: defaultColors.$citizenLightPurple
            }
        },
    },
    selected: {}
}

const selectPopup = {
    selectPopup: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    }
}

const utilityBarStyle = {
    utilityBar: {
        display: 'flex',
        flexDirection: 'row',
        ...createFonts(14),
        color: '#767b83',
        backgroundColor: '#f0f2f5',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8,
        height: 30,
        '& $clearButton': {
            marginLeft: 8,
            ...createFonts(12),
            color: defaultColors.$citizenPurple,
            cursor: 'pointer'
        },
    },
    clearButton: {}
}


export default {
    ...utilityBarStyle,
    ...selectPopup,
    ...selectItemStyles,
    ...selectStyles
}
