import React, {useState} from "react";
import Modal from "../Components/Modal/Modal";
import ModalContainer from "./Modal/ModalContainer";

let ModalApp = (props)=>{
    const [open, setOpen] = useState(false)
    window.CITIZEN_PAY_COMPONENT.open = () =>{
        setOpen(true)
    }

    const handleCloseModal = () => {
        setOpen(false)
    }

    return <Modal open={open} onClose={handleCloseModal}>
        <ModalContainer {...props} closeModal={handleCloseModal}/>
    </Modal>
}

export default ModalApp
