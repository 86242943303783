import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useStyles from "../../styles";
//
// A wrapper component for the native input.
//
const BaseInput = ({
  label, name, style, className, onClick, onChange, onBlur, value,
  type, placeholder, focus, disabled, helperText, error, checked
}) => {

  const [ref, setRef] = useState()

  if (focus) {
    ref && ref.focus()
  }

  const classes = useStyles()

  const helperTextClassNames = `
    ${classes.helperText}
    ${error? classes.error: ''}
  `

  const labelStyles = `${classes.inputLabel} 
    ${value? classes['with-label']:''} ${error? classes['with-error']:''}`

  return <div className={classes.baseInputWrapper}>
    <input ref={node => setRef(node)}
           type={type}
           style={style}
           className={`${classes.baseInput} ${className}`}
           onClick={onClick}
           onChange={onChange}
           onBlur={onBlur}
           placeholder={placeholder}
           autoFocus={focus}
           name={name}
           disabled={disabled}
           checked={checked}
           value={value}/>
    <label className={labelStyles}>{label}</label>
      <span className={helperTextClassNames}>{helperText}</span>
  </div>
}

BaseInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  className:  PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
}

export default BaseInput
