import React, { Component, createRef, Fragment } from 'react'
import popupStyles from "../styles/components/popupStyles";

const enchantedTrigger = (trigger, popperRef) => {
  class EnchantedTrigger extends Component {
    constructor (props) {
      super(props)
      this.trigger = createRef()
      this.getPosition = this.getPosition.bind(this)
      this.isOutOfViewport = this.isOutOfViewport.bind(this)
    }

    getPosition () {
      let top = this.trigger.offsetTop + 30
      let left = this.trigger.offsetLeft
      let triggerWidth = this.trigger.offsetWidth
      let popperWidth = popperRef.current.offsetWidth
      let triggerHeight = this.trigger.offsetHeight
      let popperHeight = popperRef.current.offsetHeight

      let popperBounding = popperRef.current.getBoundingClientRect()

      let isOut = this.isOutOfViewport(popperBounding)

      if (isOut.bottom) {
        top = top - (popperHeight - triggerHeight)
      }
      if (isOut.right) {
        left = left - (popperWidth - triggerWidth)
      }
      if(left < 0){
        left = 16
      }
      return ({ top, left, minWidth: triggerWidth})
    }

    isOutOfViewport (bounding) {
      // Check if it's out of the viewport on each side
      let out = {}
      out.top = bounding.top < 0
      out.left = bounding.left < 0
      out.bottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)
      out.right = bounding.right + 250 > (window.innerWidth || document.documentElement.clientWidth)
      out.any = out.top || out.left || out.bottom || out.right
      out.all = out.top && out.left && out.bottom && out.right
      return out
    };

    render () {
      const Trigger = trigger
      return <div onClick={this.props.onClick} ref={(n) => this.trigger = n} className={this.props.name}><Trigger
        name={this.props.name} {...this.props}/></div>
    }
  }

  return EnchantedTrigger
}

class Popup extends Component{
  constructor(props) {
    super(props)
    this.triggerRef = createRef();
    this.popperRef = createRef();
    this.state = {
      triggerPosition:{left: 0, top: 0},
      open: false
    }
    this.setTriggerPosition = this.setTriggerPosition.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.calculatePosition = this.calculatePosition.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.hasSomeParentTheClassPopup = this.hasSomeParentTheClassPopup.bind(this)
  }

  componentDidMount() {
    this.calculatePosition()
    window.addEventListener('resize', this.calculatePosition, false)
    document.addEventListener('click', this.handleClick, false)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.calculatePosition, false)
    document.removeEventListener('click', this.handleClick, false)
  }

  calculatePosition() {
    this.setTriggerPosition(this.triggerRef.current.getPosition())
  }

  setTriggerPosition(triggerPosition){
    this.setState({triggerPosition})
  }

  handleClick({ target }) {
    if (target.name === 'popup-trigger' + this.props.name ||
      this.hasSomeParentTheClassPopup(target, 'popper') ||
      this.hasSomeParentTheClassPopup(target, 'popup-trigger' + this.props.name)) {
      return
    }
    this.handleClose()
  }

  handleClose() {
    this.setState({open:false})
  }

  handleOpen() {
    this.popperRef.current.focus()
    if (!this.state.open && !this.props.disabled){
      this.setState({open:true})
    }
  }

  hasSomeParentTheClassPopup(element, classname) {
    if (element.className && element.className.split && element.className.split(' ').indexOf(classname) >= 0) return true
    return element.parentNode && this.hasSomeParentTheClassPopup(element.parentNode, classname)
  }

  render() {

    const { children, style = {}, trigger = () => <div/>, classname = '', position = 'bottom', name=''} = this.props

    const TriggerComponent = enchantedTrigger(trigger, this.popperRef)

     const classes = popupStyles.classes
    const popperClassNames =  `${classes.popup} ${this.state.open?classes.open: ''} ${classes[position]} ${classname}`

    return <Fragment>
      <TriggerComponent ref={this.triggerRef} name={'popup-trigger' + name} onClick={this.handleOpen}/>
      <div ref={this.popperRef}
           className={popperClassNames}
           style={{ ...style, ...this.state.triggerPosition }}>
        {children}
      </div>
    </Fragment>
  }
}

export default Popup
