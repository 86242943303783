import axios from "axios";

const GetFonts = (family) => {
    const url = `https://fonts.googleapis.com/css?family=${family}`

    return axios.get(url)
        .then(result=>result)
        .catch(err=>{
            throw new Error(err)
        })
}

export default GetFonts
