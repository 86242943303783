import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import CheckoutComponent from "../../../Components/CheckoutComponent";
import {actions} from "../../../state/ParamsState";
import {determineSticky, setupPayment, setupScript, throttle} from "../../../utils/commonUtils";

const StandalonePreviewComponent = (props) => {
    const [ref, setRef] = useState(null)
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)
    const [stickyStyle, setStickyStyle] = useState({position: 'fixed'})
    const [hasEventListener, setHasEventListener] = useState(false)
    const [isInitiatingPayment, setInitiatingPayment] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!hasEventListener && ref) {
            if(window.innerWidth > 624) {
                window.addEventListener('scroll', throttle(() => {
                    determineSticky(ref, setStickyStyle)
                }, 100))
                setHasEventListener(true)
            } else {
                setStickyStyle({position: 'initial'})
            }
        }
        return () => {
            if(window.innerWidth > 624) {
                window.removeEventListener('scroll', throttle(() => {
                    determineSticky(ref, setStickyStyle)
                }, 100))
            }
        }
    }, [hasEventListener, ref])

    const {params} = props

    useEffect(() => {
        if (!hasLoadedScripts) {
            setHasLoadedScrips(true)
            setupScript(window, params?.paymentDetails?.publicApiKey)
        }
    }, [hasLoadedScripts, params])


    if (!params) {
        return null
    }

    const {paymentDetails, styles, theme} = params

    if(!paymentDetails) {
        console.error('Payment details are mandatory.')
        return null
    }

    const {customerEmailAddress, amount} = paymentDetails

    let createPayment = (userEmail = customerEmailAddress) => {
        return setupPayment(window, setError, setInitiatingPayment, userEmail, paymentDetails)
    }

    return (
        <div ref={setRef} style={stickyStyle}>
            <CheckoutComponent createPayment={createPayment}
                               styles={{...styles}}
                               error={error}
                               loading={isInitiatingPayment}
                               amount={amount}
                               theme={theme}
                               customerEmailAddress={customerEmailAddress}/>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {params: {...state?.ParamsReducer?.params}}
}

const dispatchActionToProps = {
    ...actions
}

export default connect(mapStateToProps, dispatchActionToProps)(StandalonePreviewComponent)
