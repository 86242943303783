import PaymentInit from "../networking/calls/Payment-init";
import {THEME_STYLES} from "./Enums";

export const generateStyles = (styles = {}, stylesToExtract = ['fontFamily', 'backgroundColor', 'color']) =>{
    let finalStyles = {}
    stylesToExtract.forEach(styleAttribute => {
        if(styles[styleAttribute]){
            finalStyles[styleAttribute] = styles[styleAttribute]
        }
    })
    return finalStyles
}

export const throttle = (callback, delay) => {
    let throttleTimeout = null;
    let storedEvent = null;

    const throttledEventHandler = event => {
        storedEvent = event;

        const shouldHandleEvent = !throttleTimeout;

        if (shouldHandleEvent) {
            callback(storedEvent);

            storedEvent = null;

            throttleTimeout = setTimeout(() => {
                throttleTimeout = null;

                if (storedEvent) {
                    throttledEventHandler(storedEvent);
                }
            }, delay);
        }
    };

    return throttledEventHandler;
};

export function debounce(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
        let context = this;
        let args = arguments;

        let later = function() {
            timeout = null;
            func.apply(context, args);
        };

        let callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

export const determineSticky = (currentReference, setStyle) =>{
    if(currentReference) {
           let bounding = currentReference.getBoundingClientRect()
           let parentBounding = currentReference?.parentNode?.getBoundingClientRect()

        if((parentBounding?.height + parentBounding?.y) < bounding.bottom) {
               setStyle({position: 'absolute',bottom:0})
           } else if(bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)){
               setStyle({position: 'fixed'})
           }
    }
}

export const validateEmail = (value) => value?
    !!value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
    : false

export const setupPayment = async (parent, setError, setInitiatingPayment, userEmail, paymentDetails, successCallbackWrapper) => {

    const {merchantEmailAddress, customerEmailAddress, successCallback, apiKey, publicApiKey, amount, ...rest} = paymentDetails

    let sendPayment = (transactionId, userEmail) => {
        parent.CITIZEN_PAYMENT.startPaymentJourney(transactionId,userEmail,{
            successCallback: successCallbackWrapper ? successCallbackWrapper(successCallback) : successCallback
        });
    }

    setError(false)
    if(validateEmail(userEmail)) {
        let paymentDetails = {
            customerEmailAddress: userEmail,
            merchantEmailAddress,
            amount,
            ...rest
        }
        setInitiatingPayment(true)
        await PaymentInit(apiKey, paymentDetails).then(result =>{
            setInitiatingPayment(false)
            sendPayment(JSON.stringify(result.data) , userEmail)
        })
    } else {
        setError('Please insert a valid email')
    }
}

export const setupScript = (parent, publicApiKey) =>{
    if(publicApiKey) {
        let script = parent.document.createElement('script');
        script.src = `${process.env.SDK_BASE_URL}/sdk/sdk-payment.js`;
        script.onload = function () {
            parent.CITIZEN_PAYMENT.init({
                publicApiKey,
            })
        };
        parent.document.body.appendChild(script);
    }
}

export const filterStylesByTheme = (theme, styles) =>{
    const themeStyles = THEME_STYLES[theme]
    const distinctStyles = {}

    Object.entries(styles).forEach(([key, value])=>{
        Object.entries(value).forEach(([attributeKey, attributeValue]) => {
            if(styles[key] && themeStyles[key] ) {
                if (styles[key][attributeKey] !== themeStyles[key][attributeKey]) {
                    distinctStyles[key] = {}
                    distinctStyles[key][attributeKey] = attributeValue
                }
            }
        })
    })

    return distinctStyles
}
