import React from 'react';

const WhiteStep1 = () => {
    return (
        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" >
            <title>Group 2</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-42.000000, -221.000000)">
                    <g id="Group-6-Copy" transform="translate(9.000000, 0.000000)">
                        <g id="Group-2" transform="translate(33.000000, 221.000000)">
                            <circle id="Oval" fill="#484848" cx="16" cy="16" r="16"/>
                            <polygon id="1" fill="#FFFFFF" fillRule="nonzero" points="17.671875 22 17.671875 10.7265625 15.9296875 10.7265625 12.9609375 12.8203125 12.9609375 14.609375 15.7890625 12.609375 15.921875 12.609375 15.921875 22"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};


export default WhiteStep1;
