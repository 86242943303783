import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import CustomButton from "../../../Components/CustomButtons/CustomButton";
import Modal from "../../../Components/Modal/Modal";
import CheckoutComponent from "../../../Components/CheckoutComponent";
import {actions} from "../../../state/ParamsState";
import {setupPayment, setupScript} from "../../../utils/commonUtils";

const ModalPreviewComponent = (props) => {
    const [open, setOpen] = useState(false)
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)
    const [isInitiatingPayment, setInitiatingPayment] = useState(false)
    const [error, setError] = useState(false)

    const closeModal = () => {
        setOpen(false)
    }

    const {params} = props

    useEffect(() => {
        if (!hasLoadedScripts) {
            setHasLoadedScrips(true)
            setupScript(window, params?.paymentDetails?.publicApiKey)
        }
    }, [hasLoadedScripts, params])

    if (!params) {
        return null
    }

    const {paymentDetails, styles, theme} = params

    if(!paymentDetails) {
        console.error('Payment details are mandatory.')
        return null
    }

    const {customerEmailAddress, amount} = paymentDetails

    let createPayment = (userEmail = customerEmailAddress) => {
        return setupPayment(window, setError, setInitiatingPayment, userEmail, paymentDetails)
            .then(() => closeModal())
    }

    return (
        <div>
            <CustomButton onClick={() => setOpen(true)}> Open Modal</CustomButton>
            <div>
                Usage: To open the modal just call the window.CITIZEN_PAY_COMPONENT.open()
            </div>
            <Modal open={open} onClose={closeModal}>
                <CheckoutComponent createPayment={createPayment}
                                   styles={styles}
                                   error={error}
                                   amount={amount}
                                   theme={theme}
                                   loading={isInitiatingPayment}
                                   customerEmailAddress={customerEmailAddress}/>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {params: {...state?.ParamsReducer?.params}}
}

const dispatchActionToProps = {
    ...actions
}

export default connect(mapStateToProps, dispatchActionToProps)(ModalPreviewComponent)
