import React from 'react';

const WhiteTickIcon = () => {
    return (
        <svg width="15px" height="13px" viewBox="0 0 15 13" version="1.1" >
            <title>Path</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-17.000000, -95.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="noun_tick_1296917" transform="translate(15.000000, 89.000000)">
                        <g id="Group">
                            <g id="Path">
                                <polygon points="3.65454748 12.2405232 2.07622175 13.8899116 7.61471773 18.9768997 16.4684833 7.82445459 14.6469013 6.43641497 7.34682073 15.6317959"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default WhiteTickIcon;
