import React from 'react'
import PropTypes from 'prop-types'
import useStyles from '../../styles'

const RadioButton = ({name, checked, label, onChange}) =>{
    const classes = useStyles()
    return (
        <label className={classes.radioContainer}>{label}
            <input className={classes.radioInput} type={'radio'} name={name} checked={checked} onChange={onChange}/>
            <span className={`${classes.radioCheckmark} ${checked? classes.radioChecked : ''}`}/>
        </label>
    )
}

RadioButton.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func
}

export default RadioButton
