import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import BaseInput from './BaseInput'
import DropdownIcon from '../../assets/images/dropdown_arrow.svg'
import Popup from '../Popup'
import useStyles from '../../styles'
import popupStyles from '../../styles/components/popupStyles'

//
// The base component of the select. It uses the popup component to wrap the select options and the base input as trigger
// of the popup.
//
const BaseSelect = ({
  label, onClick, onChange, onBlur, name, disabled, placeholder,
  style, className, value, focus, options, children, multiple
}) => {

  const popupRef = useRef()
  const classes = useStyles()
  const popupClasses = popupStyles.classes

  const textFieldClassNames = `${classes.select} ${disabled ? classes.disabled : ''}`

  let selectStyle = { backgroundImage: `url(${DropdownIcon})`, ...style }

  const handleOnClickItem = ({ target: { value: userInput } }) => {
    //In case is a simple dropdown, close the popup after selecting the value
    if (!multiple) {
      if (userInput === 'All') {
        onChange({ target: { value: null, name } })
      } else {
        onChange({ target: { value: userInput, name } })
      }
      popupRef.current.handleClose()
    } else {
      const isSelected = value && value.indexOf(userInput) > -1
      const multiSelectValue = isSelected ? value.filter(r => r !== userInput) : [...value, userInput]
      onChange({ target: { value: multiSelectValue, name } })
    }
  }

  const handleClear = () => {
    onChange({ target: { value: multiple ? [] : '', name } })
  }

  const optionsAsObject = {}
  options.forEach(opt => {
    optionsAsObject[opt.value] = opt.name
  })
  const textValueDisplay = multiple ? value.map(v => optionsAsObject[v]) : optionsAsObject[value]


  const Trigger = (props) => <BaseInput type={'text'}
                                        {...props}
                                        value={textValueDisplay}
                                        label={label}
                                        style={selectStyle}
                                        focus={focus}
                                        className={textFieldClassNames}
                                        placeholder={placeholder}
                                        onClick={onClick}
                                        name={name}
                                        disabled={disabled}
                                        onBlur={onBlur}/>

  const numberOfChildren = React.Children.count(children)
  const heightOfSelectPopup = numberOfChildren * (34)
  const popupHeight = heightOfSelectPopup + (multiple ? 23 : 0)

  return <Popup ref={popupRef}
                name={'baseSelect'}
                classname={popupClasses.selectPopup}
                disabled={disabled}
                style={{ height: `${popupHeight}px` }}
                trigger={Trigger}>
    {
      multiple && <div className={classes.utilityBar}>
        <span>{`${value.length} selected`}</span>
        <div className={classes.clearButton} onClick={handleClear}>CLEAR</div>
      </div>
    }
    {
      React.Children.map(children, (Child) => {
        const childrenExtraProps = Child.props.elementName === 'SelectItem' ? { onClick: handleOnClickItem } : {}
        return React.cloneElement(Child, childrenExtraProps)
      })
    }
  </Popup>
}

BaseSelect.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  focus: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string
    })),
  multiple: PropTypes.bool
}

BaseSelect.defaultProps = {
  placeholder:'',
  style:{},
  className:'',
  value:'',
  focus:false,
  multiple:false
}

export default BaseSelect
