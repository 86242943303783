import {createFonts} from '../fonts'
import defaultColors from '../colors'

const buttonStyles = {
    'btn-align-bottom': {
        height: '100%',
        verticalAlign: 'bottom',
    },
    btn: {
        borderRadius: 2,
        textAlign: 'center',
        fontSize: '1.5rem',
        transition: 'all 0.3s ease-in-out',
        textTransform: 'none',
        cursor: 'pointer',
        margin: '4px 0 0 0',
        outline: 'none',
        '& $fixed': {
            '&--width': {
                width: 200
            }
        },

        '&--primary': {
            backgroundColor: defaultColors.$citizenPurple,
            color: defaultColors.$citizenWhite,
            borderRadius: 4,
            ...createFonts(16),
            padding: 8,
            fontWeight: 600,
            '&:hover': {
                backgroundColor: '#614EB1',
                color: defaultColors.$citizenWhite,
            },
            '& $admin': {
                padding: 8,
                margin: '0 8px',
            },
            admin: {}
        },
        '&--secondary': {
            color: defaultColors.$citizenPurple,
            borderColor: defaultColors.$citizenPurple,
            borderWidth: 2,
            borderRadius: 4,
            ...createFonts(16),
            padding: 8,
            fontWeight: 600,
            backgroundColor: defaultColors.$citizenWhite,

            '&:hover': {
                backgroundColor: '#F0F2F5',
                color: defaultColors.$citizenPurple,
            }
        },
        '&--text': {
            color: defaultColors.$citizenPurple,
            ...createFonts(16),
            padding: 8,
            fontWeight: 400,
            backgroundColor: defaultColors.$citizenWhite,
            '&:hover': {
                backgroundColor: '#F0F2F5',
                color: defaultColors.$citizenPurple,
            }
        },
        '&--disabled': {
            borderColor: defaultColors.$citizenGrey,
            backgroundColor: defaultColors.$citizenGrey,
            color: defaultColors.$citizenDarkenGrey,
            '&:hover': {
                color: defaultColors.$citizenGrey,
            }
        }
    },
    fixed: {},
    admin: {},
    disabled: {}
}

export default buttonStyles
