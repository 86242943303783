export default {
    $citizenBlack: '#000',

    $citizenWhite: '#FFF',
    $citizenSoftenWhite: '#fbfbff',
    $citizenDarkenWhite: '#ECF1F9',

    $citizenSoftGrey: '#F9F9FA',
    $citizenVeryLightGrey: '#D3DAE5',
    $citizenLightGrey: '#C4CCD8',
    $citizenGrey: '#A7ACB4',
    $citizenDarkenGrey: '#767B83',
    $citizenDarkerGrey: '#2e333b',
    $citizenDarkestGrey: '#14141b',

    $citizenPurple: '#4E39A8',
    $citizenLightPurple: '#9988E5',
    $citizenVeryLightPurple: '#f8f1ff',
    $citizenDarkPurple: '#312951',
    $citizenPink: '#f8f1ff',

    $citizenStrongRed: '#C3172E',
    $citizenRed: '#EE6175',
    $citizenSoftRed: '#E35D4D',

    $citizenScarlet: '#da4a65',
    $citizenSoftScarlet: '#FF3E5F',

    $citizenSoftOrange: '#FE9867',
    $citizenPaleOrange: '#FFE2AF',

    $citizenGreen: '#007387',
    $citizenLightGreen: '#B2F597',
    $citizenSuccessColor: '#b8e986',

    $citizenLightTurquoise: '#00DAE9',
    $citizenDarkTurquoise: '#003D7A',

    $citizenLightBlue: '#35EAFF',
//$citizenDarkBlue: '#1F2C52',
    $citizenDarkBlue: '#113385',
    $citizenSetupBlue: '#113385',
}
