import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import CustomButton from "../../../Components/CustomButtons/CustomButton";
import {JssProvider} from "react-jss";
import NewWindow from "../../../Components/NewWindow";
import CheckoutComponent from "../../../Components/CheckoutComponent";
import {actions} from "../../../state/ParamsState";
import {setupPayment, setupScript} from "../../../utils/commonUtils";

const WindowedPreviewComponent = (props) => {
    const [showWindow, setShowWindow] = useState(false)
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)
    const [parent, setParent] = useState(null)
    const [isInitiatingPayment, setInitiatingPayment] = useState(false)
    const [error, setError] = useState(false)

    const {params} = props

    useEffect(() => {
        if (!hasLoadedScripts && parent) {
            setHasLoadedScrips(true)
            setupScript(parent, params?.paymentDetails?.publicApiKey)
        }
    }, [parent, hasLoadedScripts, params])

    if (!params) {
        return null
    }

    const {paymentDetails, styles, theme} = params

    if (!paymentDetails) {
        console.error('Payment details are mandatory.')
        return null
    }

    const {customerEmailAddress, amount} = paymentDetails

    const successCallbackWrapper = (successCallback) => () =>{
        parent.close()
        successCallback && successCallback()
    }

    let createPayment = (userEmail = customerEmailAddress) => {
        if (parent) {
            return setupPayment(parent, setError, setInitiatingPayment, userEmail, paymentDetails, successCallbackWrapper)
        }
        return () => {
        }
    }

    const handleOnCloseWindow = () => {
        setShowWindow(false)
    }

    return (
        <div>
            <CustomButton onClick={() => setShowWindow(true)}> Open Window</CustomButton>
            <div>
                Usage: To open the Window just call the window.CITIZEN_PAY_COMPONENT.open() function
            </div>
            {showWindow ? <JssProvider>
                <NewWindow getWindow={setParent} onUnload={handleOnCloseWindow} on copyStyles={true}>
                    {parent && <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{
                            borderRadius: '4px',
                            boxShadow: ' 0 3px 9px 0 rgba(65, 65, 65, 0.2)',
                            padding: '8px',
                            border: '1px solid gba(0, 0, 0, 0.3)'
                        }}>
                            <CheckoutComponent createPayment={createPayment}
                                               styles={styles}
                                               amount={amount}
                                               error={error}
                                               theme={theme}
                                               loading={isInitiatingPayment}
                                               customerEmailAddress={customerEmailAddress}/>
                        </div>
                    </div>}
                </NewWindow>
            </JssProvider> : null}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {params: {...state?.ParamsReducer?.params}}
}

const dispatchActionToProps = {
    ...actions
}

export default connect(mapStateToProps, dispatchActionToProps)(WindowedPreviewComponent)
