import defaultColors from "../colors";

export const checkboxStyles = {
    '& $baseInputWrapper': {
        '& $baseInput': {
            opacity: 0,
            zIndex: 1,
            cursor: 'pointer',
            height: 20,
            width: 20,
            margin: 0,
            '&:focus + & $inputLabel': {
                fontSize: 16,
                bottom: 0
            },
        },
        '& $inputLabel': {
            paddingLeft: 10,
            marginTop: 8,
            marginBottom: 0,
            fontSize: 16,
            color: defaultColors.$citizenDarkenGrey,
            fontWeight: 500,
            fontFamily: defaultColors.$citizenFontRegularSF,
            display: 'inline-block',
            '& $with-value': {
                fontSize: 'initial',
                bottom: 'initial'
            },
            'with-value': {},
            '&::before': {
                content: '""',
                height: 16,
                width: 16,
                borderRadius: 3,
                border: '1px solid',
                left: 0,

                /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
                 *to vertically center it.
                 */
                top: -18,
                position: 'absolute',

                /*Needed for the line-height to take effect*/
                display: 'inline-block'
            },

            '&::after': {
                content: 'none',
                height: 5,
                width: 9,
                borderLeft: `2px solid ${defaultColors.$citizenWhite}`,
                borderBottom: `2px solid ${defaultColors.$citizenWhite}`,
                borderColor: defaultColors.$citizenWhite,
                transform: 'rotate(-45deg)',
                left: 4,
                top: -14,
                position: 'absolute',
                /*Needed for the line-height to take effect*/
                display: 'inline-block'
            }
        },
    },
    '&$checked': {
        '& $baseInputWrapper': {
            '& $baseInput': {
                '&:focus + & $inputLabel': {
                    fontSize: 16,
                    bottom: 0
                }
            },
            '& $inputLabel': {
                '&::before': {
                    backgroundColor: defaultColors.$citizenPurple
                },
                '&::after': {
                    content: '""'
                }
            }
        },
        '& $disabled': {
            '& $baseInputWrapper': {
                '& $inputLabel': {
                    '&::before': {
                        backgroundColor: defaultColors.$citizenLightGrey
                    },
                    '&:after': {
                        content: '""'
                    }
                },
                '& $baseInput': {
                    cursor: 'initial'
                }
            }
        },
        disabled: {}
    },
    checked: {},
}
