import defaultColors from '../colors'
import {flexRow} from "../flexStyles";

const StylePickerStyles = {
    stylePickerContainer: {
        border: '#d4d4d4 solid 1px',
        marginTop: '16px',
        position: 'relative',
        padding: '16px 8px 8px 8px'
    },
    stylePickerTitle: {
        position: 'absolute',
        top: '-13px',
        padding: '0 4px',
        backgroundColor: 'white',
        fontSize: '18px',
        fontWeight: '600'
    },
    stylePickerRow: {
        ...flexRow
    },
    stylePickerLabel: {
        width: '50%',
        color: defaultColors.$citizenDarkenGrey
    },
    stylePickerNote: {
        width: 'calc(50% - 8px)',
        color: defaultColors.$citizenDarkenGrey,
        fontSize: 14,
        padding:'4px',
        margin:'4px',
        backgroundColor: defaultColors.$citizenSoftGrey
    },
    stylePickerInput: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column'
    }
}

export default StylePickerStyles
