import React from 'react';

const MobileIcon = ({className}) => {
    return (
        <svg className={className} width="56px" height="97px" viewBox="0 0 56 97" version="1.1">
            <title>noun_Mobile_1312059</title>
            <desc>Created with Sketch.</desc>
            <g id="dropdown-variations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="noun_Mobile_1312059" fill="#000000" fillRule="nonzero">
                    <path d="M55.6,59.2 C55.6,69.1 55.6,79 55.6,89 C55.6,90.2 55.3,91.4 54.9,92.5 C54.4,93.7 53.5,94.6 52.4,95.2 C51.3,95.8 50.1,96.1 48.8,96.1 C35.1,96.1 21.4,96.1 7.8,96.1 C6.3,96.1 4.8,95.8 3.4,95 C3.2,94.9 2.9,94.7 2.7,94.6 C1.2,93.5 0.4,91.9 0.3,90.1 C0.3,89.3 0.3,88.6 0.3,87.8 C0.3,61.2 0.3,34.7 0.3,8.1 C0.3,5.7 0.9,3.6 2.9,2.2 C3.1,2 3.4,1.9 3.7,1.8 C4.9,1.2 6.2,0.9 7.6,0.9 L50.1,0.9 C54.2,1.8 55.8,4.8 55.7,8.8 C55.6,18.4 55.7,28.1 55.6,37.7 M51.2,12 C51.2,11.7 51.2,11.3 51.2,11 L4.8,11 L4.8,84 L51.1,84 C51.1,83.6 51.1,83.2 51.1,82.8 C51.1,74.6 51.1,66.5 51,58.3 M31.2,89.6 C31.2,87.8 29.8,86.4 28,86.4 C26.3,86.4 24.8,87.8 24.8,89.6 C24.8,91.4 26.2,92.9 28,92.9 C29.7,92.8 31.2,91.3 31.2,89.6 Z M28,5.4 C25.9,5.4 23.7,5.4 21.6,5.4 C21,5.4 20.5,5.5 20.5,6.2 C20.5,6.9 21,6.9 21.6,6.9 C25.9,6.9 30.2,6.9 34.5,6.9 C35,6.9 35.6,6.8 35.6,6.2 C35.6,5.5 35.1,5.4 34.5,5.4 C32.3,5.4 30.1,5.4 28,5.4 Z" id="Shape"/>
                </g>
            </g>
        </svg>
    );
};

export default MobileIcon;
