import React from 'react';

const WhiteStep3 = () => {
    return (
        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
            <title>Group 5</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-346.000000, -221.000000)">
                    <g id="Group-6-Copy" transform="translate(9.000000, 0.000000)">
                        <g id="Group-5" transform="translate(337.000000, 221.000000)">
                            <circle id="Oval-Copy-2" fill="#484848" cx="16" cy="16" r="16"/>
                            <path d="M16.1640625,22.2734375 C18.515625,22.2734375 20.2890625,20.8671875 20.2890625,18.9453125 C20.2890625,17.3984375 19.3203125,16.3984375 17.8203125,16.171875 L17.8203125,16.0390625 C19,15.71875 19.8515625,14.8203125 19.859375,13.453125 C19.8671875,11.84375 18.546875,10.453125 16.21875,10.453125 C13.84375,10.453125 12.375,11.8203125 12.2578125,13.765625 L13.9140625,13.765625 C14.015625,12.609375 14.8359375,11.9609375 16.1171875,11.9609375 C17.3828125,11.9609375 18.1328125,12.7265625 18.1328125,13.6953125 C18.1328125,14.75 17.2890625,15.4921875 16.0703125,15.4921875 L14.7109375,15.4921875 L14.7109375,16.9296875 L16.1171875,16.9296875 C17.5859375,16.9296875 18.4921875,17.65625 18.4921875,18.828125 C18.4921875,19.9609375 17.5234375,20.7265625 16.140625,20.7265625 C14.7734375,20.7265625 13.828125,20.03125 13.7265625,18.953125 L12.046875,18.953125 C12.140625,20.9609375 13.78125,22.2734375 16.1640625,22.2734375 Z" id="3" fill="#FFFFFF" fillRule="nonzero"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default WhiteStep3;
