import React, {useState} from 'react';
import PropTypes from 'prop-types'
import BanksIcon from '../assets/images/svg/banks'
import TextField from "./CustomInputComponents/TextField";
import CustomButton from "./CustomButtons/CustomButton";
import {generateStyles} from "../utils/commonUtils";
import {THEME_ASSETS, THEME_MODES} from "../utils/Enums";
import useStyles from '../styles'

const CheckoutComponent = ({createPayment, customerEmailAddress, amount, styles, loading, error, theme}) => {

    const classes = useStyles()
    const [userEmail, setUserEmail] = useState(customerEmailAddress)

    const containerStyles = generateStyles(styles.container)
    const titleStyles =  generateStyles(styles.title)
    const descriptionStyles = generateStyles(styles.description)
    const stepsStyles = generateStyles(styles.steps)
    const emailStyles = generateStyles(styles.emailContainer)
    const textfieldStyles = generateStyles(styles.textField)
    const actionTextStyles = generateStyles(styles.actionText)
    const buttonStyles = generateStyles(styles.button)

    const TickIcon = THEME_ASSETS[theme].tickIcon
    const Step1Icon = THEME_ASSETS[theme].step1Icon
    const Step2Icon = THEME_ASSETS[theme].step2Icon
    const Step3Icon = THEME_ASSETS[theme].step3Icon
    const MobileIcon = THEME_ASSETS[theme].mobileIcon

    const showEmailField = styles?.emailContainer?.display !== 'none'
    return (
        <div className={classes.container} style={containerStyles}>
            <div className={classes.title} style={titleStyles}>Pay direct from your bank</div>
            <div className={classes.bankIcons}>
                <BanksIcon/>
            </div>
            <div className={classes.description} style={descriptionStyles}>
                <div className={classes.descriptionTip}>
                    <TickIcon/>
                    <span> <span className={classes.bold}>No</span> need for your card or acount number & sort code</span>
                </div>
                <div className={classes.descriptionTip}>
                    <TickIcon/>
                    <span> <span className={classes.bold}>No</span> new accounts to create</span>
                </div>
                <div className={classes.descriptionTip}>
                    <TickIcon/>
                    <span> <span className={classes.bold}>Protected</span> by your own banks security</span>
                </div>
            </div>
            <div className={classes.steps} style={stepsStyles}>
                <div className={classes.step}>
                    <Step1Icon/>
                    <span>Enter your email</span>
                </div>
                <div className={classes.step}>
                    <Step2Icon/>
                    <span>Select your bank</span>
                </div>
                <div className={classes.step}>
                    <Step3Icon/>
                    <span>Approve payment in your online banking</span>
                </div>
            </div>
            { showEmailField && <div className={classes.emailContainer} style={emailStyles}>
                <span className={classes.emailLabel}>
                    Email<span className={classes.emailLabelDescription}>(to send you a link to select your bank)</span>
                </span>
                <TextField onChange={({target: {value}}) => {setUserEmail(value)}}
                           value={userEmail}
                           error={!!error}
                           style={textfieldStyles}
                           helperText={error ? error : ''}
                           className={classes.email}
                           type={'text'}/>
            </div>}
            <div className={classes.actionContainer} style={actionTextStyles}>
                <div className={classes.actionTextContainer}>
                    <MobileIcon className={classes.mobileIcon}/>
                    <span>Open the email on your <span className={classes.bold}>phone</span> to pay faster & easier via your bank app (recommended)</span>
                </div>
                <CustomButton style={{width:'100%', border:0, ...buttonStyles}} disabled={loading} onClick={()=>createPayment(userEmail)}>
                    {`Continue with £${amount} payment`}
                </CustomButton>
            </div>
        </div>
    )
};

CheckoutComponent.propTypes = {
    createPayment: PropTypes.func,
    customerEmailAddress: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    styles: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    theme: PropTypes.oneOf(Object.values(THEME_MODES))
}

CheckoutComponent.defaultProps = {
    styles: {},
    error: '',
    theme: THEME_MODES.LIGHT
}

export default CheckoutComponent;
