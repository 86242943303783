import React from 'react'
import BaseButton from './BaseButton'
import PropTypes from 'prop-types'

const CustomButton = ({onClick, color, className, children, disabled, justIcon, style}) =>{

  return <BaseButton onClick={onClick}
                     color={color}
                     className={className}
                     justIcon={justIcon}
                     style={style}
                     disabled={disabled}>
    {children}
  </BaseButton>
}

CustomButton.propTypes = {
  color: PropTypes.oneOf(['primary','secondary','delete','text','transparent']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  justIcon: PropTypes.bool,
  style: PropTypes.object
}

CustomButton.defaultProps = {
  color: 'primary',
  className: '',
  style: {}
}

export default CustomButton