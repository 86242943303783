import React, {useEffect, useState} from 'react';
import {setupPayment, setupScript} from "../../utils/commonUtils";
import CheckoutComponent from "../../Components/CheckoutComponent";

const WindowView = ({paymentDetails, parent, styles, theme}) => {
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)
    const [isInitiatingPayment, setInitiatingPayment] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!hasLoadedScripts) {
            setHasLoadedScrips(true)
            setupScript(parent, paymentDetails && paymentDetails.publicApiKey)
        }
    }, [parent, hasLoadedScripts, paymentDetails])

    if (!paymentDetails || !parent) {
        console.error('Payment details are mandatory.')
        return null
    }

    const {customerEmailAddress, amount} = paymentDetails

    const successCallbackWrapper = (successCallback) => () => {
        parent.close()
        successCallback && successCallback()
    }

    let createPayment = (userEmail = customerEmailAddress) => {
        return setupPayment(parent, setError, setInitiatingPayment, userEmail, paymentDetails, successCallbackWrapper)
    }

    return (
        <div style={{display:'flex', justifyContent: 'center'}}>
            <div style={{borderRadius:'4px', boxShadow: ' 0 3px 9px 0 rgba(65, 65, 65, 0.2)', padding:'8px', border:'1px solid gba(0, 0, 0, 0.3)'}}>
            <CheckoutComponent createPayment={createPayment}
                               amount={amount}
                               error={error}
                               styles={styles}
                               theme={theme}
                               loading={isInitiatingPayment}
                               customerEmailAddress={customerEmailAddress}/>
            </div>
        </div>
    );
};

export default WindowView;
