import {flexColumn, flexRow} from "../flexStyles";
import fonts from "../fonts";
import defaultColors from "../colors";
import { jss } from 'react-jss';


export default jss.createStyleSheet({
    popup: {
        position: 'absolute',
        backgroundColor: defaultColors.$citizenWhite,
        height: 0,
        minWidth: 250,
        transition: 'height 0.1s ease-out',
        visibility: 'hidden',
        '-webkit-box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
        '-moz-box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.5)',
        zIndex: 11,
        '&$open': {
            visibility: 'initial',
            height: 130,

            '& $popperContainer': {
                ...flexColumn,
                padding: 8,
                alignItems: 'center',
                justifyContent: 'center',
            },

            '& $popperText': {
                margin: 16,
                display: 'flex',
                fontFamily: fonts.$citizenFontRegularSF,
                justifyContent: 'center',
                fontSize: 12,
                color: '#767b83',
                fontWeight: 'normal',
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                textAlign: "center",
                letterSpacing: "normal",
            },

            '& $popperActions': {
                ...flexRow,
                justifyContent: 'space-between',
            }
        },
        '&$bottom': {
            bottom: 0,
            left: 0,
        },

        '&$top': {
            top: 0,
            left: 0,
        },

        '& $popperContainer,& $popperText,& $popperActions': {
            display: "none",
        }
    },
    open:{},
    popperContainer:{},
    popperText: {},
    popperActions: {},
    bottom:{},
    top:{}
}).attach();
