import { connect } from "react-redux";
import WindowView from "./WindowView";

const mapStateToProps = ({ParamsReducer:{params}}, ownProps) => {
    if(params){
        return {...params}
    }
    return { paymentDetails:{}, ...ownProps};
};

export default connect(mapStateToProps)(WindowView);
