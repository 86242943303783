import React from 'react'
import PropTypes from 'prop-types'
import BaseInput from './BaseInput'
import inputStyles from "../../styles"

const CheckBox = ({value, onChange, name, disabled, helperText, error, style}) => {
  const handleOnchange = ({target:{name, checked}}) =>{
    onChange({target:{name,value:checked}})
  }
  const classes = inputStyles()

  return <div className={`${classes.checkbox} ${ value? classes.checked:''} ${disabled? classes.disabled: ''}`}>
    <BaseInput type={'checkbox'}
               disabled={disabled}
               name={name}
               helperText={helperText}
               error={error}
               style={style}
               onChange={handleOnchange}
               checked={value}/>
  </div>
}

CheckBox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  style: PropTypes.object
}

CheckBox.defaultProps = {
  style:{}
}

export default CheckBox
