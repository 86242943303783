const spaceAroundJC = {
    justifyContent: 'space-around'
}

const spaceAroundAI = {
    alignItems: 'space-around'
}

const centerAI = {
    alignItems: 'center'
}

const centerJC = {
    justifyContent: 'center'
}

const spaceBetweenAI = {
    alignItems: 'space-between'
}

const spaceBetweenJC = {
    justifyContent: 'space-between'
}

export const flexRow = {
    display: 'flex',
    flexDirection: 'row'
}

export const flexRowSpaceAround = {
    ...flexRow,
    ...spaceAroundJC
}

export const flexRowSpaceBetween = {
    ...flexRow,
    ...spaceAroundJC
}

export const flexRowSpaceBetweenCentered = {
    ...flexRow,
    ...spaceBetweenJC,
    ...centerAI
}


export const flexRowSpaceAroundCentered = {
    ...flexRow,
    ...spaceAroundJC,
    ...centerAI
}

export const flexColumn = {
    display: 'flex',
    flexDirection: 'column'
}

export const flexColumnSpaceAround = {
    ...flexColumn,
    ...spaceAroundJC
}

export const flexColumnSpaceBetween = {
    ...flexColumn,
    ...spaceAroundJC
}

export const flexColumnCenter = {
    ...flexColumn,
    ...centerJC
}

export const flexColumnSpaceBetweenCentered = {
    ...flexColumn,
    ...spaceBetweenJC,
    ...centerAI
}

export const flexColumnSpaceAroundCentered = {
    ...flexColumn,
    ...spaceAroundJC,
    ...centerAI
}
