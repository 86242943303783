import React, {useState} from "react";
import {JssProvider} from "react-jss";
import NewWindow from "../Components/NewWindow";
import {Provider} from "react-redux";
import store from "../store";
import WindowContainer from "./Windowed/WindowContainer";

const WindowedApp = () =>{
    const [newWindow, setWindow] = useState(null)
    const [showWindow, setShowWindow] = useState(false)

    window.CITIZEN_PAY_COMPONENT.open = () =>{
        setShowWindow(true)
    }
    const handleCloseWindow = () =>{
        setShowWindow(false)
    }
    return <JssProvider>
        {showWindow && <NewWindow getWindow={setWindow} onUnload={handleCloseWindow} copyStyles={true}>
            <Provider store={store}>
                <WindowContainer parent={newWindow}/>
            </Provider>
        </NewWindow>}
    </JssProvider>
}

export default WindowedApp
