import React from 'react';

const WhiteMobileIcon = ({className}) => {
    return (
        <svg className={className} width="35px" height="61px" viewBox="0 0 35 61" version="1.1">
            <title>Shape</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-38.000000, -372.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="noun_Mobile_1312059" transform="translate(38.000000, 372.000000)">
                        <path d="M34.75,37.228866 C34.75,43.4546392 34.75,49.6804124 34.75,55.9690722 C34.75,56.7237113 34.5625,57.4783505 34.3125,58.1701031 C34,58.9247423 33.4375,59.4907216 32.75,59.8680412 C32.0625,60.2453608 31.3125,60.4340206 30.5,60.4340206 C21.9375,60.4340206 13.375,60.4340206 4.875,60.4340206 C3.9375,60.4340206 3,60.2453608 2.125,59.742268 C2,59.6793814 1.8125,59.5536082 1.6875,59.4907216 C0.75,58.7989691 0.25,57.7927835 0.1875,56.6608247 C0.1875,56.157732 0.1875,55.7175258 0.1875,55.214433 C0.1875,38.4865979 0.1875,21.8216495 0.1875,5.09381443 C0.1875,3.58453608 0.5625,2.26391753 1.8125,1.38350515 C1.9375,1.25773196 2.125,1.19484536 2.3125,1.13195876 C3.0625,0.754639175 3.875,0.565979381 4.75,0.565979381 L31.3125,0.565979381 C33.875,1.13195876 34.875,3.0185567 34.8125,5.53402062 C34.75,11.571134 34.8125,17.671134 34.75,23.7082474 M32,7.54639175 C32,7.35773196 32,7.10618557 32,6.91752577 L3,6.91752577 L3,52.8247423 L31.9375,52.8247423 C31.9375,52.5731959 31.9375,52.3216495 31.9375,52.0701031 C31.9375,46.9134021 31.9375,41.8195876 31.875,36.6628866 M19.5,56.3463918 C19.5,55.214433 18.625,54.3340206 17.5,54.3340206 C16.4375,54.3340206 15.5,55.214433 15.5,56.3463918 C15.5,57.4783505 16.375,58.4216495 17.5,58.4216495 C18.5625,58.3587629 19.5,57.4154639 19.5,56.3463918 Z M17.5,3.39587629 C16.1875,3.39587629 14.8125,3.39587629 13.5,3.39587629 C13.125,3.39587629 12.8125,3.45876289 12.8125,3.89896907 C12.8125,4.33917526 13.125,4.33917526 13.5,4.33917526 C16.1875,4.33917526 18.875,4.33917526 21.5625,4.33917526 C21.875,4.33917526 22.25,4.27628866 22.25,3.89896907 C22.25,3.45876289 21.9375,3.39587629 21.5625,3.39587629 C20.1875,3.39587629 18.8125,3.39587629 17.5,3.39587629 Z" id="Shape"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default WhiteMobileIcon;
