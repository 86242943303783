import axios from 'axios'

const apiUrl = process.env.API_URL

export const post = ({url, data, headers = {}}) => {
    const fullUrl = apiUrl + url
    return axios.post(fullUrl, data, {headers})
        .then(result=>result)
        .catch(err=>{
            throw new Error(err)
        })
};

export const put = ({url, data, headers= {}}) => {
    const fullUrl = apiUrl + url
    return axios.put(fullUrl, data, {headers})
        .then(result=>result)
        .catch(err=>{
            throw new Error(err)
        })
};

export const get = ({url, headers = {}}) => {
    const fullUrl = apiUrl + url
    return axios.get(fullUrl, {headers})
        .then(result=>result)
        .catch(err=>{
            throw new Error(err)
        })
};
