import React, {useEffect, useState} from 'react';
import {setupPayment, setupScript} from "../../utils/commonUtils";
import CheckoutComponent from "../../Components/CheckoutComponent";

const StandaloneView = ({paymentDetails, styles, theme}) => {
    const [hasLoadedScripts, setHasLoadedScrips] = useState(false)
    const [isInitiatingPayment, setInitiatingPayment] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (!hasLoadedScripts) {
            setHasLoadedScrips(true)
            setupScript(window, paymentDetails && paymentDetails.publicApiKey)
        }
    }, [hasLoadedScripts, paymentDetails])

    if(!paymentDetails) {
        console.error('Payment details are mandatory.')
        return null
    }

    const {customerEmailAddress, amount} = paymentDetails

    let createPayment = (userEmail = customerEmailAddress) => {
        return setupPayment(window, setError, setInitiatingPayment, userEmail, paymentDetails)
    }

    return (<CheckoutComponent createPayment={createPayment}
                               styles={styles}
                               amount={amount}
                               error={error}
                               theme={theme}
                               loading={isInitiatingPayment}
                               customerEmailAddress={customerEmailAddress}/>)

};

export default StandaloneView;
