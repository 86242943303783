import {connect} from "react-redux";
import {actions} from "../../state/ParamsState";
import PreviewView from "./PreviewView";

const mapStateToProps = (state) => {
    return {params: {...state?.ParamsReducer?.params}}
}

const dispatchActionToProps = {
    ...actions
}



export default connect(mapStateToProps, dispatchActionToProps)(PreviewView);
