import React from 'react'
import PropTypes from "prop-types";
import createButtonStyles from '../../styles'

const BaseButton = ({onClick, color, children, disabled, className, style}) =>{
  const classes = createButtonStyles()
  const baseButtonClassNames = `
    ${classes.btn}  flat ${classes.btn}--${color}
    ${disabled? 'disabled':''}`

  return (
    <button onClick={onClick} style={style} className={baseButtonClassNames} disabled={disabled}>
      {children}
    </button>
  )
}

BaseButton.propTypes = {
  color: PropTypes.oneOf(['primary','secondary', 'delete', 'transparent']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  justIcon: PropTypes.bool
};

export default BaseButton
