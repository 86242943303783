import React from 'react'
import PropTypes from 'prop-types'
import BaseInput from './BaseInput'
import inputStyles from "../../styles";

const TextField = ({
  label, onClick, onChange, onBlur, name, disabled, placeholder, style,
   value, focus, helperText, error, adornment, variant, type
}) => {

  const classes = inputStyles()
  const textFieldClassNames = `
    ${classes.textfield} 
    ${error? classes.error: ''}
 `



  return <BaseInput type={type}
                    onChange={onChange}
                    value={value}
                    label={label}
                    style={style}
                    focus={focus}
                    className={textFieldClassNames}
                    placeholder={placeholder}
                    onClick={onClick}
                    name={name}
                    disabled={disabled}
                    helperText={helperText}
                    error={error}
                    adornment={adornment}
                    variant={variant}
                    onBlur={onBlur}/>
}

TextField.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  search: PropTypes.bool,
  focus: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  adornment: PropTypes.elementType,
  variant: PropTypes.oneOf(['outline', 'standard']),
  type: PropTypes.oneOf(['text', 'password'])
}

TextField.defaultProps = {
  placeholder: '',
  style: {},
  className: '',
  value: '',
  focus: false,
  helperText: '',
  error: false,
  adornment: null,
  variant: 'outline',
  type: 'text'
}

export default TextField
