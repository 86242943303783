import {flexColumn, flexColumnSpaceAround, flexRow, flexRowSpaceAroundCentered} from "../flexStyles";

const checkoutComponentStyles = {
    container: {
        all: 'initial',
        maxWidth: 450,
        height: 500,
        ...flexColumnSpaceAround,
        margin: 'auto',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: '#373d47'
    },
    bankIcons: {
        margin: '16px 0'
    },
    description: {
        backgroundColor: '#f0f2f5',
        padding: '0px 8px'
    },
    descriptionTip: {
        margin: '8px 0',
        fontSize: 16
    },
    steps: {
        ...flexRowSpaceAroundCentered,
        margin: '32px 0'
    },
    step: {
        ...flexColumn,
        alignItems: 'center',
        width: 113,
        height: 80,
        fontSize: 14,
        textAlign: 'center'
    },
    emailContainer:{
        padding: '8px 0'
    },
    emailLabel:{
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
    },
    emailLabelDescription:{
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: '#767b83'
    },
    email: {
        borderRadius: 4,
        border: 'solid 1px #a7acb4',
        padding: '10px 12px',
        outline: 'none',
        width: 'calc(100% - 24px)',
        marginTop: 4,
        fontSize: 16,
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: '#2a3649'

    },
    actionContainer: {
        padding: 8,
        backgroundColor: '#f0f2f5'
    },
    actionTextContainer: {
        ...flexRow
    },
    mobileIcon:{
        width: 32,
        height: 32,
        margin: 4
    },
    button: {

    },
    bold: {
        fontWeight: 'bold'
    }
}

export default checkoutComponentStyles
