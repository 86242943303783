import React from 'react';

const Tick = () => {
    return (
        <svg width="15px" height="13px" viewBox="0 0 15 13" version="1.1" >
            <title>Path</title>
            <desc>Created with Sketch.</desc>
            <g id="dropdown-variations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="TEST" transform="translate(-8.000000, -84.000000)" fill="#000000" fillRule="nonzero">
                    <g id="Group-10">
                        <g id="Group-19" transform="translate(0.000000, 73.000000)">
                            <g id="Group-18" transform="translate(8.000000, 8.000000)">
                                <g id="Group-15">
                                    <g id="noun_tick_1296917" transform="translate(0.000000, 3.000000)">
                                        <g id="Group">
                                            <g id="Path">
                                                <polygon points="1.65454748 6.24052317 0.07622175 7.88991162 5.61471773 12.9768997 14.4684833 1.82445459 12.6469013 0.436414974 5.34682073 9.63179592"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Tick;
