import React, {useRef} from 'react'
import PropTypes from 'prop-types'
import BaseModal from './BaseModal'
import FocusLock from 'react-focus-lock';
import {createUseStyles} from 'react-jss'

let useStyles = createUseStyles({
    dimmer: {
        position: 'fixed',
        zIndex: 999,
        background: 'rgba(0, 0, 0, 0.6)',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalContainer: {
        position: 'absolute',
        minWidth: '300px',
        minHeight: '100px',
        background: 'white',
        marginTop: '-25px',
        boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.5)',
        borderRadius: '4px',
        padding: '8px',
    }
})

const Modal = ({onClose, open, children}) => {

    const classes = useStyles()

    const modalContainerRef = useRef()
    if (!open) {
        return null
    }

    return (
        <BaseModal>
            <FocusLock>
                <div className={classes.dimmer} onClick={onClose}>
                    <div ref={modalContainerRef}
                         className={classes.modalContainer}
                         onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>
            </FocusLock>
        </BaseModal>
    )
}

Modal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}

export default Modal
