import {flexColumn} from "../flexStyles";
import fonts, {createFonts} from "../fonts";
import defaultColors from '../colors'
import {checkboxStyles} from "./checkboxStyles";

export const baseInputWrapperStyles = {
    ...flexColumn,
    position: 'relative'
}

const baseInputStyles = {
    border: 0,
    backgroundColor: '#ffffff',
    outline: 'none',
    padding: '12px 24px 4px 8px',
    backgroundPosition: 'calc(100% - 20px)',
    backgroundSize: '16px 16px',
    backgroundRepeat: 'no-repeat',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::placeholder': {
        fontFamily: fonts.$citizenFontRegularSF,
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'italic',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#a7acb4'
    },
    '&$error': {
        borderColor: defaultColors.$citizenRed
    },
    error: {}
}

const inputLabelStyles = {
    fontSize: 14,
    transition: 'all 0.3s linear',
    color: defaultColors.$citizenGrey,
    position: 'absolute',
    marginBottom: 0,
    bottom: 0,
    left: 4,
    pointerEvents: 'none',

    '& $with-error': {
        color: defaultColors.$citizenRed,
        bottom: 12
    },

    '& $with-value': {
        fontSize: 10,
        bottom: 'calc(50% + 8px)'
    },

    '& $with-error + $adornment': {
        top: 'initial',
        bottom: 8
    },

}

export const helperTextStyles = {
    ...createFonts(11),
    textAlign: 'initial',
    fontWeight: 400,
    paddingLeft: 4,
    color: defaultColors.$citizenLightGrey,

    '&$error': {
        color: defaultColors.$citizenRed,
        maxHeight: 4
    },
    error: {}
}

const textfieldStyles = {
    transition: 'all 0.3s ease-in',
    border: `1px solid ${defaultColors.$citizenGrey}`,
    borderRadius: 4,
    padding: '10px 12px',
    margin: '4px 0px',
    fontSize: 16,
    fontWeight: 500,
    color: defaultColors.$citizenDarkerGrey,
    '&:focus': {

        borderColor: defaultColors.$citizenPurple
    },
    '& $standard': {
        borderBottom: '1px solid #dbdbdb',
        '&::placeholder': {
            opacity: 0
        },
        '& $error': {
            borderColor: defaultColors.$citizenRed
        },
        '&:focus': {
            '&::placeholder': {
                opacity: 1
            },
            borderColor: defaultColors.$citizenPurple
        },

    },
}

export default {
    baseInputWrapper: baseInputWrapperStyles,
    baseInput: baseInputStyles,
    inputLabel: inputLabelStyles,
    helperText: helperTextStyles,
    textfield: textfieldStyles,
    checkbox: checkboxStyles,
    checked: {},
    error: {},
    'with-error': {},
    'adornment': {},
    'with-value': {},
    'with-label': {},
    standard:{},

}
