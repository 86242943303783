import defaultColors from "../colors";
import {createFonts} from "../fonts";

const radioButtonStyles = {
    radioContainer: {
        display: 'block',
        position: 'relative',
        paddingLeft: 24,
        marginRight: 8,
        marginBottom: 0,
        cursor: 'pointer',
        boxSizing: 'initial',
        color: defaultColors.$citizenGrey,
        ...createFonts(12),
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',

        '& $radioInput': {
            position: 'absolute',
            opacity: 0,
            cursor: 'pointer',
        },

    },
    radioInput: {},
    radioCheckmark: {
        position: 'absolute',
        top: 0,
        left: 3,
        height: 15,
        width: 15,
        boxSizing: 'initial',
        backgroundColor: defaultColors.$citizenWhite,
        border: `1px solid ${defaultColors.$citizenGrey}`,
        borderRadius: '50%',
        '&$radioChecked': {
            position: 'absolute',
            top: 0,
            left: 3,
            height: 15,
            width: 15,
            boxSizing: 'initial',
            backgroundColor: defaultColors.$citizenWhite,
            border: `1px solid ${defaultColors.$citizenPurple}`,
            borderRadius: '50%',
            '&:after': {
                content: '""',
                position: 'absolute',
                display: 'block',
                top: '28%',
                left: '28%',
                width: 7,
                height: 7,
                borderRadius: '50%',
                background: defaultColors.$citizenPurple,
            },
        }
    },
    radioChecked: {}
}

export default radioButtonStyles


